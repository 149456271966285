.hold-amt-sec {
    display: flex;
    align-items: center;

    .material {
        padding-bottom: 0px;

        .form-input {
            padding: 10px 15px;
            width: 140px;
        }

    }

    .action-btn {
        margin-left: 10px;

        .ic-check {
            color: $accent-color;
        }

        .ic-clearclose {
            color: $promotional-color-2;
        }
    }
}

.dealer-wallet-table {
    table {
        th {
            &:nth-child(5) {
                width: 200px;
            }
        }

        tr {
            &:hover {
                td {
                    background: rgba(239, 242, 249, 0.4);

                    a {
                        text-decoration: none !important;
                        color: initial !important;
                    }
                }

            }
        }
    }

    :hover {
        .action-btn {
            background: initial;

            li {
                &:hover {
                    background: $accent-color;
                    color: $white;

                    .ic-check {
                        color: $white;
                    }

                    .ic-clearclose {
                        color: $white;
                    }

                    a {
                        background: $accent-color;
                        color: $white !important;

                    }
                }
            }
        }
    }
}

.dealer-gcd-top-riben {
    ul {
        justify-content: flex-start;

        li {
            margin-right: 40px;
            text-align: left;

            label {
                .currency-symbol {
                    text-align: left !important;
                }
            }
        }
    }
}

.gcd-arrow-up {
    transform: rotate(270deg);
    display: inline-block;
    color: #D73D2D;
    font-weight: bold;
}

.gcd-arrow-down {
    transform: rotate(270deg);
    display: inline-block;
    color: #72D73E;
    font-weight: bold;
}

.gcd-transction-history-popup {
    .nav-pills {
        border-bottom: 0px;
    }

    table {
        tbody {
            tr {
                background: rgba(239, 242, 249, 0.5);

                td {

                    &:nth-child(2) {
                        border-radius: 8px 0 0 8px;
                    }

                }
            }
        }
    }
}

.dealer-outbond-table-outer {
    .financer-tab-line {
        border-bottom: 1px solid $border-color;

        .nav-link {
            padding-bottom: 10px !important;
        }
    }

    table {
        tbody {
            td {
                &:last-child {
                    padding-bottom: 0px;
                }

                &:first-child {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

            }

            .subtable {
                tr {
                    td {
                        padding-bottom: 13px;

                        &:nth-child(2) {
                            padding-top: 0px !important;
                            padding-bottom: 0px !important;
                        }

                    }
                }

                td {
                    background: $white;

                    table {
                        border-right: 1px solid $border-color;
                        border-left: 1px solid $border-color;

                        tbody {
                            tr {
                                border-bottom: 1px solid $border-color;

                                &:last-child {
                                    border-bottom: 0px;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

.p-13 {
    padding-bottom: 13px !important;
}

.lost-disbral-dealer-table {
    width: 100%;
    overflow-x: auto;

    table {
        th {
            background: #e3e7f1;
        }

        td {
            background: $white;
        }

        th,
        td {
            min-width: auto;

            &:nth-child(1) {
                width: 100px !important;
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                z-index: 9;
            }

            &:nth-child(2) {
                width: 125px;
                position: -webkit-sticky;
                position: sticky;
                left: 100px;
                z-index: 9;
            }

            &:nth-child(3) {
                width: 130px;
            }

            &:nth-child(4) {
                width: 190px;
            }

            &:nth-child(5) {
                width: 130px;
            }

            &:nth-child(6) {
                width: 145px;
            }

            &:nth-child(7) {
                width: 145px !important;
            }

            &:nth-child(8) {
                width: 180px;
            }

            &:nth-child(9) {
                width: 130px;
            }

            &:nth-child(10) {
                width: 140px;
            }

            &:nth-child(11) {
                width: 130px;
            }
        }
    }
}

.payment-requet-dealer {
    width: 175px !important;
}

.dealer-wallet-subtable {
    td {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .subtable-in-table {
        padding-left: 0px !important;
        padding-right: 0px !important;

        tr {
            td {
                &:nth-child(3) {
                    padding-left: 25px !important;
                    padding-right: 25px !important;
                }

                &:nth-child(4) {
                    padding-left: 25px !important;
                    padding-right: 25px !important;
                }
            }

        }

    }
}

.p-t-10 {
    padding-bottom: 10px;
}

.m-t-10 {
    margin-top: 10px;
}


.wallet-lock-unlock-add-remarks {
    .modal {
        .modal-main {
            padding: 40px;
            border-radius: 8px;
            width: 700px;
        }

        .modal-body {
            // overflow-y: inherit;
            padding-top: 5px;
        }

        .modal-header {
            padding-bottom: 20px;

            .sub-heading {
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: rgba($heading-color, 0.87);
                display: block;
            }
        }
    }

    fieldset {
        margin-bottom: 20px;
    }

    .material {
        .form-input {
            padding: 10px 15px;
            margin-bottom: 0px;
        }

        .form-label {
            &:before {
                top: 12px;
            }
        }
    }

    .col-md-6,
    .col-md-12 {
        padding-right: 10px;
        padding-left: 10px;
    }

    .row {
        margin-right: -10px;
        margin-left: -10px;
    }
}

.wallet-lock-unlock-history {
    td {
        position: relative;
    }

    .accordion__button {
        background: transparent;
        border: 0px;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: $heading-color;


        &:before {
            content: "\e907";
            font-family: dc !important;
            font-size: 20px;
            bottom: 1px;
            position: absolute;
            right: 10px;
            top: 18px;
            cursor: pointer;

        }
    }

    .accordion__button[aria-expanded="true"]::before,
    .accordion__button[aria-selected="true"]::before {
        content: "\e90c";
        font-family: dc !important;
    }

    .additional-history-acc-data {
        border-top: 2px solid $border-color;
        padding-top: 20px;
        margin-top: 20px;
    }

    


}

.bca-active-reason {
    // style={{paddingBottom: "10px"}}
    padding-bottom: 12px !important;
}